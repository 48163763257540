jQuery(document).ready(function ($) {

  var colorKeys = { darkblue: '#1a6593', orange: '#ff7803', yellow: '#fdce2d', green: '#80d061', blue: '#36b6cd', lightblue: '#a8e2ed', purple: '#544580', darkgreen: '#0f6b37' };

  $('li.retreat-center').each(function () {
    var borderColor = $(this).data('retreat-details').locationdetails[0].retreatcolor;
    var RGB = colorKeys[borderColor];

    $(this).children('h3').css('border-color', RGB);
  });

  //Display Modal with Retreat Location Details
  $('li.retreat-center').on('click', function (e) {
    //e.preventDefault();

    //Get Data from Retreat Center data-retreat-details attribute
    var stateInfo = $(this).data('retreat-details');
    var locationInfo = stateInfo.locationdetails;

    //Set State Image from state info
    $('.location-image img').attr('src', stateInfo.retreatimage);

    //Create variable to store HTML to add to list
    var retreatHTML = "";

    //Iterate through array of objects on location info to set location data (list item per location)
    for (var i = 0; i < locationInfo.length; i++) {
      var name = locationInfo[i].retreatname, //String
        color = locationInfo[i].retreatcolor, //String
        type = locationInfo[i].retreattype, //String
        location = locationInfo[i].retreatlocation, //String
        amenities = locationInfo[i].retreatamenities, //Array
        link = locationInfo[i].retreatlink; //String

      var RGB = colorKeys[color];
      var alpha = '0.1';
      var overlayColor = '(' + parseInt(RGB.substring(1, 3), 16) + ',' + parseInt(RGB.substring(3, 5), 16) + ',' + parseInt(RGB.substring(5, 7), 16) + ',' + alpha + ')';

      $('.location-image .overlay').attr('style', 'background-color: rgba' + overlayColor + ';');

      retreatHTML += '<li class="city">' +
        '<h2><div class="city-icon"><span class="cicle-container" style="background-color: ' + RGB + ';"><span class="fa fa-star"></span></span></div>' + name + '<div class="sub-text">' + type + '</div></h2>' +
        '<h3>' + location + '</h3>' +
        '<ul>';

      for (var j = 0; j < amenities.length; j++) {
        retreatHTML += "<li>" + amenities[j] + "</li>";
      }

      retreatHTML += '<li><a href="' + link + '" class="learn-more">Learn More</a></li>' +
        '</ul>' +
        '</li>';
    }

    //Append built HTML
    $('.location-cities > ul')[0].innerHTML = retreatHTML;

    //Show Modal
    $('.location.modal-container').addClass('active');
    $('body').addClass('fixed-body');
  });

});